import Api from '@/axios'

export default {
  getAllCountries (name) {
    return Api().get(`countries?title=${name}`)
  },
  getSettings () {
    return Api().get('settings')
  }
}
