import Api from '@/axios'
export default {
  gatTourHistoryReport (filter) {
    return Api().get('leads/tours', {
      params: filter
    })
  },
  startTour (data) {
    return Api().post('leads/tours/store', data)
  },
  editTour (id, comment, trainerId) {
    return Api().patch(`leads/tours/${id}?comment=${comment}&trainer_id=${trainerId}`)
  }
}
