<template>
  <b-row>
    <b-modal id="addMyLead" size="lg" title="Add My Lead" hide-footer no-close-on-backdrop>
      <addMyLead :leads="userInfo.data" />
    </b-modal>
    <b-modal id="showStart" title="Start Tour" centered hide-footer no-close-on-backdrop>
      <h4 class="text-black font-weight-bold">Are you sure you wan't start a tour ?</h4>

      <!-- <v-select class="w-100 mt-2" label="" :options="allUsers" v-model="trainer_id" :filterable="false" @search="onSearchCreatedBy" :reduce="option => option.id" placeholder="Choose Trainer">
        <template slot="no-options">
          type to search about Trainers...
        </template>
        <template slot="option" slot-scope="option">
          <div class="d-center">
            {{ option.name }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ option.name }}
          </div>
        </template>
      </v-select> -->
      <div class="d-flex justify-content-end mt-2">
        <b-button v-if="startLoading"  variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          saving...
        </b-button>
        <b-button v-else variant="primary" class="mx-1" @click = "startTour">Start Now</b-button>
      </div>
    </b-modal>
    <b-modal id="endTour" title="End Tour" centered hide-footer no-close-on-backdrop>
      <h4 class="text-black font-weight-bold mb-2">Are you sure you wan't end this tour ?</h4>

      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(end)">

          <v-select class="w-100 mt-2" label="" :options="allUsers" v-model="trainer_id" :filterable="false" @search="onSearchCreatedBy" :reduce="option => option.id" placeholder="Choose Trainer">
            <template slot="no-options">
              type to search about Trainers...
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.name }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.name }}
              </div>
            </template>
          </v-select>

          <b-form-group label="Comment:" label-for="comment">
            <b-textarea class="bg-white" v-model="comment"></b-textarea>
          </b-form-group>

          <div class="d-flex justify-content-end mt-2">
            <b-button v-if="startLoading"  variant="primary" disabled>
              <b-spinner small type="grow"></b-spinner>
              saving...
            </b-button>
            <b-button v-else variant="primary" class="mx-1" type="submit">end</b-button>
          </div>
        </form>
      </ValidationObserver>

    </b-modal>
    <b-col sm="12" v-if="userInfo">
      <iq-card body-class=" profile-page p-0">
        <template v-slot:body>
          <div class="profile-header">
            <div class="profile-info p-2">
              <b-row v-if="!loading">
                <b-col md="6" sm="12">
                  <div class="user-detail pl-5">
                    <div class="d-flex flex-wrap align-items-center">
                      <div class="profile-img pr-4">
                        <b-img v-bind:src="profile" ref="file" alt="profile-img" fluid class="avatar-80 cursor-pointer" />
                      </div>
                      <div class="profile-detail d-flex align-items-center">
                        <h4>{{userInfo.data.name}}</h4>
                      </div>
                      <div class="profile-detail d-flex align-items-center">
                        <h5 class="m-0 pl-3 text-secondary"> {{userInfo.data.phone}} </h5>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col md="6" sm="12" class="d-flex align-items-center justify-content-end">
                  <b-button v-if="loadingTable"  variant="primary" disabled class="mx-1">
                    <b-spinner small type="grow"></b-spinner>
                    loading...
                  </b-button>
                  <template v-else>
                    <b-button v-if="allTour.length" :variant=" allTour[0].end_at ? 'success' : 'danger' " class="mx-1" @click="allTour[0].end_at ? showStartTour() : showToStop()">{{ allTour[0].end_at ? 'Start' : 'End' }} Tour</b-button>
                    <b-button v-else @click="showStartTour()" class="mx-1" variant="success"> Start Tour </b-button>
                    <b-button variant="primary" @click="showAddMyLead">Add My Lead</b-button>
                  </template>
                  </b-col>
              </b-row>
            </div>
          </div>
        </template>
      </iq-card>
    </b-col>
    <b-col sm="12">
      <div class="iq-card p-1">
        <b-tabs content-class="" align="left">
          <b-tab  title="History" @click="activeTap = 'history'" :active="activeTap == 'history'">
          </b-tab>
          <b-tab  title="Tour" @click="activeTap = 'tour'" :active="activeTap == 'tour'">
          </b-tab>
        </b-tabs>
      </div>
    </b-col>
    <b-col sm="12" v-if="activeTap == 'history'">
      <b-row>
        <b-col lg="12" class="profile-center">
          <iq-card class="rounded pb-1">
            <template v-slot:body>
            <b-table :busy="loadingTable" striped responsive :fields="columns" :items="allHistory" class="mb-0 table-borderless">
              <template v-slot:table-busy>
                <div class="text-center">
                  <b-spinner small type="grow"></b-spinner>
                  Loading...
                </div>
              </template>
              <template v-slot:cell(call_status)="data3">
                {{data3.item.call_status? data3.item.call_status.title:'ــــــــ'}}
              </template>
              <template v-slot:cell(user)="data3">
                {{data3.item.user.name}}
              </template>
              <template v-slot:cell(date)="data3">
                {{data3.item.date?data3.item.date.substr(0,10):'ــــــــ'}}
              </template>
              <template v-slot:cell(period)="data3">
                {{data3.item.period?data3.item.period.substr(0,10):'ــــــــ'}}
              </template>
              <template v-slot:cell(source)="data3">
                {{data3.item.source?data3.item.source.title:'ــــــــ'}}
              </template>
              <template v-slot:cell(inquiry)="data3">
                {{data3.item.inquiry?data3.item.inquiry.title:'ــــــــ'}}
              </template>
              <template v-slot:cell(project)="data3">
                {{data3.item.project?data3.item.project.en_title:'ــــــــ'}}
              </template>
              <template v-slot:cell(comment)="data3">
                {{data3.item.comment?data3.item.comment:'ــــــــ'}}
              </template>
            </b-table>
            </template>
            <b-pagination v-model="currentPage"
                          class="mt-3"
                          :total-rows="total"
                          align="right"
                          :per-page="per_page"
                          aria-controls="my-table"
                          @input="changeContent"
            ></b-pagination>
          </iq-card>
        </b-col>
      </b-row>
    </b-col>
    <b-col sm="12" v-else>
      <b-row>
        <b-col lg="12" class="profile-center">
          <iq-card class="rounded pb-1">
            <template v-slot:body>
            <b-table :busy="loadingTable" striped responsive :fields="columnTour" :items="allTour" class="mb-0 table-borderless">
              <template v-slot:table-busy>
                <div class="text-center">
                  <b-spinner small type="grow"></b-spinner>
                  Loading...
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <p class="holdSelection">{{timeConvert(data.item.created_at)}}</p>
              </template>
              <template v-slot:cell(sales)="data">
                <p>{{data.item.sales.name}}</p>
              </template>
              <template v-slot:cell(trainer)="data">
                <p>{{data.item.trainer.name}}</p>
              </template>
              <!-- <template v-slot:cell(action)="data">
                <b-button variant=" iq-bg-danger mr-1 mb-1" v-if="!data.item.end_at" size="sm" @click="showToStop(data.item)">
                  <i class="las la-stop-circle"></i>
                </b-button>
              </template> -->
            </b-table>
            </template>
            <b-pagination v-model="pagination.currentPage"
                          class="mt-3"
                          :total-rows="pagination.total"
                          align="right"
                          :per-page="pagination.per_page"
                          aria-controls="my-table"
                          @input="getTour"
            ></b-pagination>
          </iq-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { core } from '../../config/pluginInit'
import addMyLead from '../../components/leads/addMyLead'
import tourServices from '../../modules/tour/services/tour'
export default {
  name: 'leadProfile',
  mounted () {
    core.index()
  },
  components: {
    addMyLead
  },
  data () {
    return {
      allTour: [],
      // selectedTour: null,
      comment: null,
      trainer_id: null,
      filter: {
        lead_id: this.$route.params.id
      },
      pagination: {
        currentPage: 1,
        per_page: 10,
        total: 6
      },
      columnTour: [
        { label: 'Project', key: 'project.en_title', class: 'text-center' },
        { label: 'Start Time', key: 'created_at', class: 'text-left' },
        { label: 'End Time', key: 'end_at', class: 'text-left' },
        { label: 'comment', key: 'comment', class: 'text-left' },
        { label: 'Trainer', key: 'trainer', class: 'text-left' },
        { label: 'sales', key: 'sales', class: 'text-left' }
      ],
      activeTap: 'history',
      loading: false,
      startLoading: false,
      loadingTable: false,
      userInfo: {},
      allHistory: [],
      profile: require('../../assets/images/user/user.jpg'),
      columns: [
        { label: 'Status', key: 'call_status', class: 'text-left' },
        { label: 'Comment', key: 'comment', class: 'text-left' },
        { label: 'User Name', key: 'user', class: 'text-left' },
        { label: 'Date', key: 'date', class: 'text-left' },
        { label: 'End_Period', key: 'period', class: 'text-left' },
        { label: 'Inquiry', key: 'inquiry', class: 'text-left' },
        { label: 'Project', key: 'project', class: 'text-left' },
        { label: 'Source', key: 'source', class: 'text-left' }
      ],
      allUsers: [],
      currentPage: 1,
      per_page: 10,
      total: 6
    }
  },
  props: ['newId'],
  watch: {
    '$route.params.id' (newId) {
      this.loadingTable = true
      this.getInfoAboutMember()
      this.$store.dispatch('gitHistory', { id: newId, pageNumber: 1 }).then(res => {
        this.allHistory = res.data.data.data
        // this.loading = false
      }).finally(() => {
        this.loadingTable = false
      })
    }
  },
  methods: {
    onSearchCreatedBy (data, loading) {
      if (data.length) {
        loading(true)
        this.$store.dispatch('serachAboutUser', { user: data, permissions: ['trainer'] }).then(res => {
          this.allUsers = res.data.data
          loading(false)
        }).catch(() => {
          loading(false)
        })
      }
    },
    getTour () {
      tourServices.gatTourHistoryReport({ page: this.pagination.currentPage, lead_id: this.$route.params.id }).then(res => {
        this.allTour = res.data.data
        this.pagination.currentPage = res.data.meta.current_page
        this.pagination.per_page = res.data.meta.per_page
        this.pagination.total = res.data.meta.total
      }).finally(() => {
        this.loadingTable = false
      })
    },
    startTour () {
      this.startLoading = true
      tourServices.startTour({ lead_id: this.$route.params.id }).then(res => {
        this.$bvModal.hide('showStart')
        core.showSnackbar('success', res.data.message)
        this.getTour()
      }).finally(() => {
        this.startLoading = false
      })
    },
    end () {
      this.startLoading = true
      tourServices.editTour(this.$route.params.id, this.comment, [this.trainer_id]).then(res => {
        this.$bvModal.hide('endTour')
        core.showSnackbar('success', res.data.message)
        this.comment = null
        this.getTour()
      }).finally(() => {
        this.startLoading = false
      })
    },
    showStartTour () {
      this.$bvModal.show('showStart')
    },
    showToStop (obj) {
      // this.selectedTour = obj.id
      this.$bvModal.show('endTour')
    },
    showAddMyLead () {
      this.$bvModal.show('addMyLead')
    },
    getInfoAboutMember () {
      this.loading = true
      this.$store.dispatch('GetSpacificLead', this.$route.params.id).then(res => {
        this.userInfo = res.data
        this.loading = false
      })
    },
    getLeadHistory () {
      this.loadingTable = true
      this.$store.dispatch('GetSpacificLead', this.$route.params.id).then(res => {
        this.userInfo = res.data
        this.loadingTable = false
      })
    },
    showHistory () {
      this.loadingTable = true
      this.$store.dispatch('gitHistory', { id: this.$route.params.id, pageNumber: 1 }).then(res => {
        this.allHistory = res.data.data.data
        this.loadingTable = false
      })
    }
  },
  created () {
    this.getInfoAboutMember()
    this.showHistory()
    this.getTour()
  }
}
</script>
<style scoped>
.profile-img{
  margin-top: 0 !important;
}
</style>
