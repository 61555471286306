<template>
    <div>
        <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Tour History</h4>
            </template>
            <template v-slot:body>
                <b-table :busy="loadingTable" striped responsive :fields="columnTour" :items="allTour" class="mb-0 table-borderless">
                    <template v-slot:table-busy>
                      <div class="text-center">
                        <b-spinner small type="grow"></b-spinner>
                        Loading...
                      </div>
                    </template>
                    <template v-slot:cell(user)="data1">
                        <router-link :to="{name:'leadProfile', params:{id:data1.item.lead_id}}">{{data1.item.lead.name}}</router-link>
                    </template>
                    <template v-slot:cell(trainer)="data">
                      <p>{{data.item.trainer ? data.item.trainer.name : '---'}}</p>
                    </template>
                    <template v-slot:cell(created_at)="data">
                      <p class="holdSelection">{{timeConvert(data.item.created_at)}}</p>
                    </template>
                    <template v-slot:cell(sales)="data">
                      <p>{{data.item.sales.name}}</p>
                    </template>
                  </b-table>
              <b-pagination v-model="pagination.currentPage"
              class="mt-3"
              :total-rows="pagination.total"
              align="right"
              :per-page="pagination.per_page"
              aria-controls="my-table"
              @input="getAllUpgrades"
                ></b-pagination>
            </template>
          </iq-card>
    </div>
</template>
<script>
import tourServices from '@/modules/tour/services/tour'
export default {
  data () {
    return {
      allTour: [],
      pagination: {
        currentPage: 1,
        per_page: 10,
        total: 6
      },
      columnTour: [
        { label: 'Project', key: 'project.en_title', class: 'text-center' },
        { label: 'User Name', key: 'user', class: 'text-left' },
        { label: 'Start Time', key: 'created_at', class: 'text-left' },
        { label: 'End Time', key: 'end_at', class: 'text-left' },
        { label: 'comment', key: 'comment', class: 'text-left' },
        { label: 'sales', key: 'sales', class: 'text-left' },
        { label: 'Trainer', key: 'trainer', class: 'text-left' },
        { label: 'Ended By', key: 'ended_by', class: 'text-left' }
      ]
    }
  },
  methods: {
    getTour () {
      tourServices.gatTourHistoryReport({ page: this.pagination.currentPage }).then(res => {
        this.allTour = res.data.data
        this.pagination.currentPage = res.data.meta.current_page
        this.pagination.per_page = res.data.meta.per_page
        this.pagination.total = res.data.meta.total
      }).finally(() => {
      })
    }
  },
  created () {
    this.getTour()
  }
}
</script>
