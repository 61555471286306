// Layout
const VerticleLayout = () => import('../../../layouts/VerticleLayout')

// Reports (sales)
const mainReports = () => import('../views/mainReport')
const packageReports = () => import('../views/Packages/packageReports')
const leadReports = () => import('../views/Leads/leadReports')
const saleReports = () => import('../views/Sales/saleReports')
const salesReportDetails = () => import('../views/Sales/details')
const salesReportHistories = () => import('../views/Sales/histories')
const salesReportLeads = () => import('../views/Sales/leads')
const salesReportAchieved = () => import('../views/Sales/achieved')
const salesReportPayable = () => import('../views/Sales/payable')
const salesReportContracts = () => import('../views/Sales/contracts')
const salesReportReceived = () => import('../views/Sales/leadReseved')

// Reports (members)
const memberReports = () => import('../views/Members/memberReports')
const memberNotStart = () => import('../views/Members/memberNotStart')
const memberReportVisits = () => import('../views/Members/visits')
const memberReportInvitations = () => import('../views/Members/invititions')
const memberReportFreeze = () => import('../views/Members/freeze')
const memberReportPayments = () => import('../views/Members/payments')
const memberReportDiscounts = () => import('../views/Members/discount')
const memberReportUpgrades = () => import('../views/Members/upgrade')
const memberTourHistory = () => import('../views/Members/tourHistory.vue')
const allMemberReports = () => import('../views/Members/allMembers.vue')
const allPackagesReport = () => import('../views/Packages/packageDetails.vue')
const allPurchases = () => import('../views/Packages/purchases')
const memberRemaining = () => import('../views/Members/remaining.vue')
const expireSoon = () => import('../views/Members/expireSoon.vue')
// start Routes
export default [
  {
    path: '/reports',
    name: 'reports',
    component: VerticleLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'reports',
        meta: { auth: true, name: 'reports' },
        component: mainReports,
        children: [
          {
            path: 'members',
            name: 'allMemberReports',
            meta: { auth: true, name: 'allMemberReports' },
            component: memberReports,
            children: [
              {
                path: '',
                name: 'allMemberReports',
                meta: { auth: true, name: 'allMemberReports' },
                component: allMemberReports
              },
              {
                path: 'not-start',
                name: 'memberNotStart',
                meta: { auth: true, name: 'memberNotStart' },
                component: memberNotStart
              },
              {
                path: 'expire-soon',
                name: 'expireSoon',
                meta: { auth: true, name: 'expireSoon' },
                component: expireSoon
              },
              {
                path: 'visits',
                name: 'memberReportVisits',
                meta: { auth: true, name: 'memberReportVisits' },
                component: memberReportVisits
              },
              {
                path: 'invitations',
                name: 'memberReportInvitations',
                meta: { auth: true, name: 'memberReportInvitations' },
                component: memberReportInvitations
              },
              {
                path: 'freeze',
                name: 'memberReportFreeze',
                meta: { auth: true, name: 'memberReportFreeze' },
                component: memberReportFreeze
              },
              {
                path: 'payments',
                name: 'memberReportPayments',
                meta: { auth: true, name: 'memberReportPayments' },
                component: memberReportPayments
              },
              {
                path: 'remaining',
                name: 'memberReportRemaining',
                meta: { auth: true, name: 'memberReportRemaining' },
                component: memberRemaining
              },
              {
                path: 'discount',
                name: 'memberReportDiscounts',
                meta: { auth: true, name: 'memberReportDiscounts' },
                component: memberReportDiscounts
              },
              {
                path: 'upgrades',
                name: 'memberReportUpgrades',
                meta: { auth: true, name: 'memberReportUpgrades' },
                component: memberReportUpgrades
              },
              {
                path: 'tour-history',
                name: 'memberTourHistory',
                meta: { auth: true, name: 'tourHistory' },
                component: memberTourHistory
              }
            ]
          },
          {
            path: 'package',
            name: 'packageReports',
            meta: { auth: true, name: 'packageReports' },
            component: packageReports,
            children: [
              {
                path: '',
                name: 'allPackages',
                meta: { auth: true, name: 'allPackages' },
                component: allPackagesReport
              },
              {
                path: 'purchases/:id',
                name: 'allPurchases',
                meta: { auth: true, name: 'allPurchases' },
                component: allPurchases
              }
            ]
          },
          {
            path: 'lead',
            name: 'leadReports',
            meta: { auth: true, name: 'leadReports' },
            component: leadReports
          },
          {
            path: 'sales',
            name: 'saleReports',
            meta: { auth: true, name: 'saleReports' },
            component: saleReports,
            children: [
              {
                path: '',
                name: 'salesReportDetails',
                meta: { auth: true, name: 'salesReportDetails' },
                component: salesReportDetails
              },
              {
                path: 'histories',
                name: 'salesReportHistories',
                meta: { auth: true, name: 'salesReportHistories' },
                component: salesReportHistories
              },
              {
                path: 'leads',
                name: 'salesReportLeads',
                meta: { auth: true, name: 'salesReportLeads' },
                component: salesReportLeads
              },
              {
                path: 'archived',
                name: 'salesReportAchieved',
                meta: { auth: true, name: 'salesReportAchieved' },
                component: salesReportAchieved
              },
              {
                path: 'payable',
                name: 'salesReportPayable',
                meta: { auth: true, name: 'salesReportPayable' },
                component: salesReportPayable
              },
              {
                path: 'contracts',
                name: 'contract_amount',
                meta: { auth: true, name: 'contract_amount' },
                component: salesReportContracts
              },
              {
                path: 'leads',
                name: 'salesReportLeads',
                meta: { auth: true, name: 'salesReportLeads' },
                component: salesReportLeads
              },
              {
                path: 'reserved',
                name: 'salesLeadsReserved',
                meta: { auth: true, name: 'salesLeadsReserved' },
                component: salesReportReceived
              }
            ]
          }
        ]
      }
    ]
  }
]
