<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" v-if="hasPer('account.category.daily')">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Daily Desk</h4>
            </template>
            <template v-slot:body>
              <div v-if="isBusyTable" class="text-center">
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </div>
              <b-table striped responsive  class="mb-0 table-borderless text-center" :items="dailyDisk" v-else>
                <template #thead-top>
                  <b-tr>
                    <b-th colspan="4" class="text-center">Total Revenue </b-th>
                    <b-th colspan="4" class="text-center">Total Refund</b-th>
                    <b-th colspan="2" class="text-center">Total Expenses</b-th>
                    <b-th class="text-center">Withdraw</b-th>
                    <b-th colspan="4" class="text-center">Total Transfer</b-th>
                    <b-th colspan="4" class="text-center">Net Profit</b-th>
                  </b-tr>
                </template>
                <template #bottom-row>
                    <b-td colspan="4" class="text-center">{{ totalRow.totalRevenue }} </b-td>
                    <b-td colspan="4" class="text-center">{{ totalRow.totalRefund }}</b-td>
                    <b-td colspan="2" class="text-center">{{ totalRow.totalExpenses }}</b-td>
                    <b-td class="text-center">{{ totalRow.withdraw }}</b-td>
                    <b-td colspan="4" class="text-center">{{ totalRow.totalTransfer }}</b-td>
                    <b-td colspan="4" class="text-center">{{ totalRow.netProfit }}</b-td>
                </template>
              </b-table>
            </template>
          </iq-card>
        </b-col>
        <b-col md="12">
          <date-range-picker v-if="hasPer('account.category.projects.daily')"
                             ref="picker"
                             :date-format="dateFormat"
                             opens=false
                             :timePicker=false
                             :timePicker24Hour=false
                             :showWeekNumbers=true
                             :showDropdowns=true
                             format='mm/dd/yyyy'
                             direction="center"
                             :autoApply=true
                             v-model="dateRangeTotal"
                             :linkedCalendars=true
                             customRangeLabel= 'Custom Range'
                             @update="getTotalDetailsInAllProjects"
          >
          </date-range-picker>
        </b-col>
        <b-col lg="12" v-if="hasPer('account.category.projects.daily')">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Total  <span class="ml-2 font-weight-bold cursor-pointer" @click="getTotalDetailsInAllProjects"><i class="las la-sync text-primary"></i></span></h4>
            </template>
            <template v-slot:body>
              <div v-if="loadTotalTable" class="text-center">
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </div>
              <b-table striped responsive  class="mb-0 table-borderless" :items="allDetailsInAllProjects" v-else>
              </b-table>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="3" md="6" v-for="(i,key) in Object.keys(allModeratorsWidget)" :key="key">
          <iq-card>
            <template v-slot:body>
              <div class="text-center"><span> {{ i }} </span></div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="value-box">
                  <h2 class="mb-0"><Counter :value="allModeratorsWidget[i]" /></h2>
                </div>
                <div class="iq-iconbox iq-bg-primary">
                  <i class="ri-arrow-up-line" />
                </div>
              </div>
              <div class="iq-progress-bar-linear d-inline-block w-100  mt-5">
                <Progressbar value="80" color="primary" />
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="3" md="6">
          <iq-card>
            <template v-slot:body>
              <div class="text-center"><span> Total </span></div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="value-box">
                  <h2 class="mb-0">{{sumOfLeads}}</h2>
                </div>
                <div class="iq-iconbox iq-bg-danger">
                  <i class="ri-arrow-up-line" />
                </div>
              </div>
              <div class="iq-progress-bar-linear d-inline-block w-100  mt-5">
                <Progressbar :value="80" color="danger" />
              </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import { Arabic } from 'flatpickr/dist/l10n/ar'
import { english } from 'flatpickr/dist/l10n/default'
import { mapGetters } from 'vuex'
import Swiper from '../../components/core/slider/Swiper'
import SwiperNav from '../../components/core/slider/SwiperNav'
import SwiperSlide from '../../components/core/slider/SwiperSlide'
import Counter from '../../components/core/counter/Counter'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import deskModule from '../../components/dashboard/desk'
import accountingService from '../../modules/accounting/services/accounting'
import globalServices from '@/globalServices.js'
export default {

  name: 'Dashboard2',

  /* filters: {
    date(val) {
      return val ? val.toLocaleString() : ''
    }
  } */
  // eslint-disable-next-line vue/no-unused-components
  components: { Counter, Swiper, SwiperNav, SwiperSlide, DateRangePicker, deskModule },
  mounted () {
    core.index()
  },
  watch: {
    rtl: function (value) {
      this.options.rtl = this.rtl
    }
  },
  computed: {
    ...mapGetters({
      rtl: 'Setting/rtlModeState',
      lang: 'Setting/langState',
      getAllRevunue: 'getAllRevunue'
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    sumOfLeads () {
      var total = 0
      for (var el in this.allModeratorsWidget) {
        total += parseInt(this.allModeratorsWidget[el])
      }
      return total
    }
  },
  methods: {
    getSettings () {
      globalServices.getSettings().then(response => {
        this.numberOfDays = response.data.data.find(data => {
          return data.key === 'date_range'
        }).value
      }).then(() => {
        this.dateFormat()
      })
    },
    dateFormat (classes, date) {
      if (this.hasPer('file.show')) {
        return classes
      } else {
        console.log('this.numberOfDays => ', this.numberOfDays)
        const currentDate = new Date()
        const pastDate = new Date(currentDate)
        pastDate.setDate(pastDate.getDate() - this.numberOfDays)
        if (!classes.disabled) {
          classes.disabled = date.getTime() < new Date(pastDate)
        }
        return classes
      }
    },
    getTotalDetailsInAllProjects () {
      var Totals = {
        branch: 'Total',
        cash: 0,
        expense: 0,
        income: 0,
        refund: 0,
        transfer: 0,
        visa: 0,
        paymob: 0,
        vodafone_cash: 0,
        withdraw: 0,
        net_cash: 0,
        net_profit: 0,
        _rowVariant: 'primary'
      }
      this.allDetailsInAllProjects = []
      accountingService.getTotalDetailsInAllProjects({ startDate: new Date(this.dateRangeTotal.startDate).toJSON().slice(0, 10), endDate: new Date(this.dateRangeTotal.endDate).toJSON().slice(0, 10) }).then(res => {
        Object.entries(res.data.data).forEach(([key, value]) => {
          value = {
            branch: key,
            ...value,
            net_cash: parseFloat(value.cash) + parseFloat(value.vodafone_cash) - parseFloat(value.refund) - parseFloat(value.expense) - parseFloat(value.withdraw),
            net_profit: parseFloat(value.income) - parseFloat(value.refund) - parseFloat(value.expense) - parseFloat(value.withdraw)
          }
          Totals.income += parseFloat(value.income)
          Totals.withdraw += parseFloat(value.withdraw)
          Totals.expense += parseFloat(value.expense)
          Totals.transfer += parseFloat(value.transfer)
          Totals.refund += parseFloat(value.refund)
          Totals.cash += parseFloat(value.cash)
          Totals.vodafone_cash += parseFloat(value.vodafone_cash)
          Totals.paymob += parseFloat(value.paymob)
          Totals.visa += parseFloat(value.visa)
          Totals.net_cash += parseFloat(value.cash) + parseFloat(value.vodafone_cash) - parseFloat(value.refund) - parseFloat(value.expense) - parseFloat(value.withdraw)
          Totals.net_profit += parseFloat(value.income) - parseFloat(value.refund) - parseFloat(value.expense) - parseFloat(value.withdraw)
          this.allDetailsInAllProjects.push(value)
        })
        this.allDetailsInAllProjects.push(Totals)
      }).finally(() => {
        console.log('sssssss', this.allDetailsInAllProjects)
        this.loadTotalTable = false
      })
    },
    reload () {
      this.isBusy = true
      // console.log({ startDate: new Date(this.dateRange.startDate).toJSON().slice(0, 10), endDate: new Date(this.dateRange.startDate).toJSON().slice(0, 10) })
      this.$store.dispatch('getAllRevenue', {
        startDate: new Date(this.dateRange.startDate).toJSON().slice(0, 10),
        endDate: new Date(this.dateRange.endDate).toJSON().slice(0, 10)
      }).then(() => {
        this.isBusy = false
      })
    },
    // dateFormat (classes, date) {
    //   if (!classes.disabled) {
    //     classes.disabled = date.getTime() < new Date()
    //   }
    //   return classes
    // },
    fixDate (event) {
      // eslint-disable-next-line no-undef
      return moment(event).format('YYYY-MM-DD')
    },
    showData () {
      this.isBusy = true
      this.$store.dispatch('getAllRevenue', { startDate: new Date(this.dateRange.startDate).toISOString().split('T')[0], endDate: new Date(this.dateRange.endDate).toJSON().slice(0, 10) }).then(() => {
        this.isBusy = false
      })
    },
    getModeratorsWidget () {
      this.$store.dispatch('getModeratorsWidget', this.typeOfFilterWidget).then(res => {
        this.allModeratorsWidget = res.data.data
      })
    }
    // eslint-disable-next-line camelcase
  },
  created () {
    this.getSettings()
    if (this.hasPer('account.category.projects.daily')) {
      this.getTotalDetailsInAllProjects()
    }
    if (this.hasPer('account.category.daily')) {
      this.dailyDisk = []
      this.$store.dispatch('getDailyDisk', { type: 'branch', startDate: new Date().toISOString().split('T')[0], endDate: new Date().toISOString().split('T')[0] }).then(res => {
        const totalRevenue = parseFloat(res.data.data.revenue_cash) + parseFloat(res.data.data.revenue_visa) + parseFloat(res.data.data.revenue_vodafone_cash) + parseFloat(res.data.data.revenue_paymob)
        const totalRefund = parseFloat(res.data.data.refund_cash) + parseFloat(res.data.data.refund_visa) + parseFloat(res.data.data.refund_vodafone_cash) + parseFloat(res.data.data.refund_paymob)
        const totalExpenses = parseFloat(res.data.data.expense_cash) + parseFloat(res.data.data.expense_visa)
        const withdraw = res.data.data.withdraw
        const totalTransfer = parseFloat(res.data.data.transfer_cash) + parseFloat(res.data.data.transfer_visa) + parseFloat(res.data.data.transfer_vodafone_cash) + parseFloat(res.data.data.transfer_paymob)
        this.totalRow = {
          totalRevenue: totalRevenue,
          totalRefund: totalRefund,
          totalExpenses: totalExpenses,
          withdraw: withdraw,
          totalTransfer: totalTransfer,
          netProfit: parseFloat(totalRevenue) - parseFloat(totalRefund) - parseFloat(totalExpenses) -
              parseFloat(withdraw)
        }
        this.dailyDisk = [{
          ...res.data.data,
          'Net Cash': parseFloat(res.data.data.revenue_cash) - (parseFloat(res.data.data.expense_cash) +
              parseFloat(res.data.data.refund_cash) + parseFloat(res.data.data.withdraw)),
          'Net Visa': parseFloat(res.data.data.revenue_visa) - (parseFloat(res.data.data.expense_visa) +
              parseFloat(res.data.data.refund_visa)),
          'Net Vodafone cash': parseFloat(res.data.data.revenue_vodafone_cash) -
              (parseFloat(res.data.data.refund_vodafone_cash)),
          'Net Paymob': parseFloat(res.data.data.revenue_paymob) -
              (parseFloat(res.data.data.refund_paymob))
        }]
      }).finally(() => {
        this.isBusyTable = false
      })
    }
    if (this.hasPer('revenue')) {
      this.$store.dispatch('getAllRevenue', { startDate: new Date(this.dateRange.startDate).toJSON().slice(0, 10), endDate: new Date(this.dateRange.endDate).toJSON().slice(0, 10) }).then(() => {
        this.isBusy = false
      })
    }
    this.isBusy = true
    this.getModeratorsWidget()
  },
  data () {
    return {
      numberOfDays: null,
      totals: '',
      allDetailsInAllProjects: [],
      dailyDisk: [],
      allModeratorsWidget: {},
      isBusy: true,
      isBusyTable: true,
      loadTotalTable: true,
      fields: [
        'index',
        { label: 'Income', key: 'income', class: 'text-center' },
        { label: 'Expense', key: 'expense', class: 'text-center' },
        { label: 'WithDraw', key: 'withdraw', class: 'text-center' },
        { label: 'Visa', key: 'visa', class: 'text-center' },
        { label: 'Cash', key: 'cash', class: 'text-center' },
        { label: 'NetProfit', key: 'netprofitwithdraw', class: 'text-center' }
      ],
      dateRange: {
        startDate: new Date(),
        endDate: new Date()
      },
      dateRangeTotal: {
        startDate: new Date(),
        endDate: new Date()
      },
      options: {
        centeredSlides: false,
        loop: true,
        slidesPerView: 1,
        autoplay: {
          delay: 4000
        },
        spaceBetween: 30,
        breakpoints: {
          320: {
            slidesPerView: 1
            // spaceBetween: 10
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1
            // spaceBetween: 15
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 1
            // spaceBetween: 20
          },
          768: {
            slidesPerView: 1
            // spaceBetween: 20
          },
          1024: {
            slidesPerView: 2
            // spaceBetween: 20
          }
        },
        pagination: {
          el: '.swiper-pagination'
        },

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar'
        }
      },
      config: {
        dateFormat: 'Y-m-d',
        inline: true,
        locale: english
      },
      arConfig: {
        dateFormat: 'Y-m-d',
        inline: true,
        locale: Arabic
      },
      totalRow: {
        totalRevenue: 0,
        totalRefund: 0,
        totalExpenses: 0,
        withdraw: 0,
        totalTransfer: 0,
        netProfit: 0
      }
    }
  }
}
</script>
<style>
.vue-daterange-picker{
  display: block !important;
}
.form-control.reportrange-text {
  border: 1px solid var(--iq-primary) !important;
  border-radius: 10px !important;
  color: var(--iq-primary) !important;
  margin: 10px 0 !important;
  height: auto !important;
  line-height: 30px !important;
  background: #fff !important;
}
</style>
