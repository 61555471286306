<template>
  <iq-card>
    <b-modal id="showStart" title="Start Tour" centered hide-footer no-close-on-backdrop>
      <h4 class="text-black font-weight-bold">Are you sure you wan't start a tour ?</h4>

      <!-- <v-select class="w-100 mt-2" label="" :options="allUsers" v-model="trainer_id" :filterable="false" @search="onSearchCreatedBy" :reduce="option => option.id" placeholder="Choose Trainer">
        <template slot="no-options">
          type to search about Trainers...
        </template>
        <template slot="option" slot-scope="option">
          <div class="d-center">
            {{ option.name }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ option.name }}
          </div>
        </template>
      </v-select> -->

      <div class="d-flex justify-content-end mt-2">
        <b-button v-if="startLoading"  variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          saving...
        </b-button>
        <b-button v-else variant="primary" class="mx-1" @click = "startTour">Start Now</b-button>
      </div>
    </b-modal>
    <b-modal id="endTour" title="End Tour" centered hide-footer no-close-on-backdrop>
      <h4 class="text-black font-weight-bold mb-2">Are you sure you wan't end this tour ?</h4>

      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(end)">
          <v-select class="w-100 mt-2" label="" :options="allUsers" v-model="trainer_id" :filterable="false" @search="onSearchCreatedBy" :reduce="option => option.id" placeholder="Choose Trainer">
            <template slot="no-options">
              type to search about Trainers...
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.name }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.name }}
              </div>
            </template>
          </v-select>

          <b-form-group label="Comment:" label-for="comment">
            <b-textarea class="bg-white" v-model="comment"></b-textarea>
          </b-form-group>

          <div class="d-flex justify-content-end mt-2">
            <b-button v-if="startLoading"  variant="primary" disabled>
              <b-spinner small type="grow"></b-spinner>
              saving...
            </b-button>
            <b-button v-else variant="primary" class="mx-1" type="submit">end</b-button>
          </div>
        </form>
      </ValidationObserver>

    </b-modal>
    <b-modal id="statusHistory" size="lg" title="History" hide-footer no-close-on-backdrop>
      <div class="border border-dark" style="padding: 10px;border: 1px solid #cbcbcb !important;margin-bottom: 16px;">
        <statusHistory :allHistory="history"/>
      </div>
    </b-modal>
    <b-modal id="rocHistory" size="lg" title="Roc History" hide-footer no-close-on-backdrop>
      <rocHistory :member-id="$route.params.id"/>
    </b-modal>
    <b-modal id="memberTourHistory" size="lg" title="Tour History" hide-footer no-close-on-backdrop>
      <b-table :busy="loadingTable" striped responsive :fields="columnTour" :items="allTour" class="mb-0 table-borderless">
        <template v-slot:table-busy>
          <div class="text-center">
            <b-spinner small type="grow"></b-spinner>
            Loading...
          </div>
        </template>
        <template v-slot:cell(trainer)="data">
          <p>{{data.item.trainer ? data.item.trainer.name : '---'}}</p>
        </template>
        <template v-slot:cell(user)="data1">
            <router-link :to="{name:'leadProfile', params:{id:data1.item.lead_id}}">{{data1.item.lead.name}}</router-link>
        </template>
        <template v-slot:cell(created_at)="data">
          <p class="holdSelection">{{timeConvert(data.item.created_at)}}</p>
        </template>
        <template v-slot:cell(sales)="data">
          <p>{{data.item.sales.name}}</p>
        </template>
      </b-table>
  <b-pagination v-model="pagination.currentPage"
    class="mt-3"
    :total-rows="pagination.total"
    align="right"
    :per-page="pagination.per_page"
    aria-controls="my-table"
    ></b-pagination>
    </b-modal>
    <b-modal id="memberIdImage" size="lg" title="Image" hide-footer no-close-on-backdrop>
      <p></p>
    </b-modal>
    <template v-slot:headerTitle>
      <h4 class="card-title">Info</h4>
    </template>
    <b-modal id="checkInModal" size="lg" title="Check In" hide-footer no-close-on-backdrop>
      <check-in-modal :member="member" :member_package="member_package" :member_Service="member_Service" :memberId="user.membership_id"/>
    </b-modal>
    <b-modal id="addMyLead" size="lg" title="Add My Lead" hide-footer no-close-on-backdrop>
      <addMyLead :leads="lead" />
    </b-modal>
    <template v-slot:body>
      <div class="about-info m-0 p-0" v-if="user.membership_id">
        <b-row class="mb-2">
          <b-col cols="3">Qr Code: </b-col>
          <b-col cols="9">
            <img alt="search button" :src="'data:image/svg+xml;base64,' + user.QrCode" />
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="3">ID: </b-col>
          <b-col cols="9">{{user.membership_id}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">CODE: </b-col>
          <b-col cols="9">{{user.lead.id}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">Email: </b-col>
          <b-col cols="9">{{user.email}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">Phone:</b-col>
          <b-col cols="9">{{user.phone}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">Birthday:</b-col>
          <b-col cols="9">{{user.birthday}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">National:</b-col>
          <b-col cols="9">{{user.national_id}}</b-col>
        </b-row>
        <b-row>
          <b-col cols="3">ID Photo: </b-col>
          <b-col cols="9">
            <span class="text-primary cursor-pointer" @click="showIdPhoto">View</span>
          </b-col>
        </b-row>
        <b-row>
          <div class="w-100">
            <b-button variant=" iq-bg-primary iq-waves-effect" class="w-100 mt-3" @click="checkin" v-if="!checkinLoading">Check In</b-button>
            <b-button variant=" iq-bg-primary iq-waves-effect" class="w-100 mt-3" v-else><spinner-loading text="Loading" /></b-button>
          </div>
          <b-button variant=" iq-bg-primary iq-waves-effect" class="mt-2 w-100" @click = goToPackage>Add Package / Service</b-button>
          <b-button variant=" iq-bg-primary iq-waves-effect" class="mt-2 w-100" @click = "showAddMyLead">Add My Lead</b-button>
          <b-button variant=" iq-bg-primary iq-waves-effect" class="mt-2 w-100" @click = "showHistory">Show History</b-button>
          <b-button variant=" iq-bg-primary iq-waves-effect" class="mt-2 w-100" @click = "showTourHistory">Show Tour History</b-button>
          <b-button variant=" iq-bg-primary iq-waves-effect" class="mt-2 w-100" @click = "showRoc">Show ROC</b-button>
          <b-button v-if="allTour.length" :variant=" allTour[0].end_at ? ' iq-bg-success iq-waves-effect' : ' iq-bg-danger iq-waves-effect' " class="mt-2 w-100" @click="allTour[0].end_at ? showStartTour() : showToStop()">{{ allTour[0].end_at ? 'Start' : 'End' }} Tour</b-button>
          <b-button v-else @click="showStartTour()" class="mt-2 w-100" variant=" iq-bg-success iq-waves-effect"> Start Tour </b-button>
          <b-button variant="primary iq-waves-effect" class="mt-2 w-100" @click = "showPdf"><i class="las la-print"></i> Card Info</b-button>
        </b-row>
      </div>
      <div class="about-info m-0 p-0" v-else>
        <div class="text-center">
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </div>
      </div>
    </template>
  </iq-card>
</template>

<script>
import { core } from '../../../config/pluginInit'

import tourServices from '@/modules/tour/services/tour'
import checkInModal from '../checkInModal'
import addMyLead from '../../../components/leads/addMyLead'
import leadService from '@/modules/leads/services/leads'
const statusHistory = () => import('@/components/statusHistory/statusHistory')
const rocHistory = () => import('@/modules/ROC/views/list.vue')
export default {
  name: 'info',
  props: {
    user: {
      type: Object
    },
    lead: {
      type: Object
    }
  },
  components: {
    checkInModal,
    addMyLead,
    statusHistory,
    rocHistory
  },
  data () {
    return {
      startLoading: false,
      allTour: [],
      trainer_id: null,
      pagination: {
        currentPage: 1,
        per_page: 10,
        total: 6
      },
      comment: null,
      columnTour: [
        { label: 'Project', key: 'project.en_title', class: 'text-center' },
        { label: 'User Name', key: 'user', class: 'text-left' },
        { label: 'Start Time', key: 'created_at', class: 'text-left' },
        { label: 'End Time', key: 'end_at', class: 'text-left' },
        { label: 'comment', key: 'comment', class: 'text-left' },
        { label: 'sales', key: 'sales', class: 'text-left' },
        { label: 'Trainer', key: 'trainer', class: 'text-left' },
        { label: 'Ended By', key: 'ended_by', class: 'text-left' }
      ],
      allUsers: [],
      loadingTable: false,
      finishSearch: true,
      member: {},
      member_package: [],
      member_Service: [],
      history: [],
      checkinLoading: false
    }
  },
  methods: {
    showStartTour () {
      this.$bvModal.show('showStart')
    },
    showToStop (obj) {
      // this.selectedTour = obj.id
      this.$bvModal.show('endTour')
    },
    getTour () {
      tourServices.gatTourHistoryReport({ page: this.pagination.currentPage, lead_id: this.user.lead.id }).then(res => {
        this.allTour = res.data.data
        this.pagination.currentPage = res.data.meta.current_page
        this.pagination.per_page = res.data.meta.per_page
        this.pagination.total = res.data.meta.total
        this.$emit('tourData', res.data.data)
      }).finally(() => {
      })
    },
    startTour () {
      this.startLoading = true
      tourServices.startTour({ lead_id: this.user.lead.id }).then(res => {
        this.$bvModal.hide('showStart')
        core.showSnackbar('success', res.data.message)
        this.getTour()
      }).finally(() => {
        this.startLoading = false
      })
    },
    end () {
      this.startLoading = true
      tourServices.editTour(this.user.lead.id, this.comment, [this.trainer_id]).then(res => {
        this.$bvModal.hide('endTour')
        core.showSnackbar('success', res.data.message)
        this.comment = null
        this.getTour()
      }).finally(() => {
        this.startLoading = false
      })
    },
    showTourHistory () {
      this.$bvModal.show('memberTourHistory')
    },
    showPdf () {
      this.$emit('click')
    },
    showIdPhoto () {
      this.$bvModal.show('memberIdImage')
    },
    onSearchCreatedBy (data, loading) {
      if (data.length) {
        loading(true)
        this.$store.dispatch('serachAboutUser', { user: data, permissions: ['trainer'] }).then(res => {
          this.allUsers = res.data.data
          loading(false)
        }).catch(() => {
          loading(false)
        })
      }
    },
    showHistory () {
      leadService.gitHistory({ id: this.lead.id, pageNumber: 1 }).then(res => {
        this.history = res.data.data.data
        this.$bvModal.show('statusHistory')
      })
    },
    showRoc () {
      this.$bvModal.show('rocHistory')
    },
    checkin () {
      this.checkinLoading = true
      this.member_package = []
      this.member_Service = []
      this.$store.dispatch('searchByMemberID', this.user.membership_id).then(res => {
        this.member = res.data.data.member
        var newArr = res.data.data.all
        for (var index = 0; index < newArr.length; index++) {
          for (var index1 = 0; index1 < newArr[index].length; index1++) {
            newArr[index][index1].active = false
            if (newArr[index][index1].type === 'package') {
              this.member_package.push(newArr[index][index1])
            } else {
              this.member_Service.push(newArr[index][index1])
            }
          }
        }
        this.openPackageId = this.member_package[0].id
        this.$bvModal.show('checkInModal')
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.checkinLoading = false
      })
    },
    goToPackage () {
      this.$router.push({ name: 'newMember', query: { member_id: this.$route.params.id } })
    },
    showAddMyLead () {
      this.$bvModal.show('addMyLead')
    }
  },
  watch: {
    user: {
      handler: function () {
        this.getTour()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
