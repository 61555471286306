<template>
  <div>
    <label class="phone-label">Phone Number:</label>
    <!-- <ValidationProvider name="color" ref="color" rules="digits:11" v-slot="{ errors }"> -->
    <div class="d-flex bg-white rounded-4 phone-number" style="border-radius: 10px;">
      <!-- {{ selectedCountries }} -->
        <v-select
          v-model="selectedCountries"
          name="project"
          id="project"
          :options="allCountries"
          :reduce="option => option.code"
          @option:selecting="selectCountry"
          @search="onSearch"
          style="width: 150px"
          placeholder="select country"
        >
        <template slot="no-options">
          no countries...
        </template>
        <template slot="option" slot-scope="option">
            {{ option.title }}
        </template>
        <template slot="selected-option" slot-scope="option">
            {{ option.title.length > 9 ? option.title.substring(0,9) + '...' : option.title }}
        </template>
        </v-select>
        <input
          type="phone"
          class="flex-grow-1 phone-input"
          placeholder="Phone Number"
          v-bind:value="value"
          v-on:input="$emit('input', $event.target.value)"
          @keyup="$emit('update')"
        />
    </div>
    <!-- <div class="invalid-feedback">
      <span>{{ errors[0] }}</span>
    </div>
  </ValidationProvider> -->
  </div>
</template>
<script>
import globalServices from '@/globalServices.js'
export default {
  data: () => ({
    selectedCountries: '',
    allCountries: []
  }),
  mounted () {
    this.getCountries()
  },
  props: ['value'],
  methods: {
    selectCountry (data) {
      this.$emit('select', data?.code)
    },
    async getCountries (search = '') {
      globalServices.getAllCountries(search).then(res => {
        this.allCountries = res.data.data
      })
    },
    async onSearch (search, loading) {
      if (search.length) {
        loading(true)
        await this.getCountries(search)
        loading(false)
      }
    }
  }
}
</script>
<style>
.phone-number {
  border:1px solid #d7dbda;
}

.phone-label {
  color: var(--iq-dark);
  font-weight: 400;
  display: block;
  font-size: 14px;
}
.phone-number .vs--searchable div{
  border-radius: 0px !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  height: 45px;
  background-color: #f2f2f2 !important;
}
.phone-input {
  border: 0;
  padding-left: 10px;
  height: 45px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
